import React, { useState, useMemo } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

// Assume this import path is correct for your project structure
import blog1 from '../assets/Blog/Blog Banner.jpg';
import portfolioBg from '../components/Image/Resolute-UI-Portfolio.jpg';

// Sample blog post data
const blogPosts = [
  {
    id: 1,
    title: "3 Guardrails of Investing: Essential Tips for Long-Term Success",
    excerpt: "Over the years we have witnessed how the stigma attached to investment that it is a highly risky business has subsided significantly. Today, investing is often seen as a pathway to wealth, but without a clear strategy and well-defined principles, it can also lead to costly mistakes...",
    content: `1. Do Not Follow the Herd
One of the biggest mistakes that many investors make is blindly following the crowd. When markets soar, there's often a frenzy where people rush to invest in trending assets, fearing they might miss out on big returns. This herd mentality is driven by immediate emotions, but it can lead to poor investment decisions. Try avoiding it and invest judiciously! Let us look at some of the reasons as to why we shouldn't follow the crowd. 

a) Market Timing is Unreliable: Attempting to time the market based on popular trends can often backfire. By the time you join the crowd, the best opportunities may have passed.
b) Emotions Can Cloud Judgment: Crowds are often driven by emotion rather than logic. Decisions based on fear or greed are likely to be shortsighted and harmful in the long run.

So, instead of jumping on the latest investment bandwagon, focus on making decisions based on thorough research and your personal financial goals. This will help you remain objective and avoid making hasty choices that can derail your financial strategy.

2. Always Think Long-Term with Proper Planning
Investing is not about getting rich overnight. It's a marathon, not a sprint. A long-term investment strategy, combined with well-thought-out planning, can yield far greater results than short-term, reactionary decisions. How about we understand the power of Long-Term thinking:

a) Compounding Returns: The longer you stay invested, the more your investments benefit from compound interest. This is particularly true for assets like stocks or real estate, where time allows value to grow exponentially.
b) Planning for the Future: Planning for the long-term is important in investing because it helps you reduce risks and grow your money over time. It allows you to handle market ups and downs, take advantage of compounding, and stay on track to reach your financial goals like retirement or other big future needs. Here are a few ways to plan.

i) Set Clear Goals: Define your short, mid, and long-term financial objectives. Whether it's retirement, buying a house, or starting a business, knowing your goals will guide your investment decisions.
ii) Assess Your Risk Profile: Understand how much risk you are comfortable with. Younger investors with a longer horizon can afford to take on more risk, while older investors may want to preserve capital with safer investments.
iii) Regularly Review and Adjust Your Plan: Life changes, and so should your investment strategy. Make sure to review your financial plan periodically to ensure that it aligns with your current situation.

c) Riding Out Market Volatility: Financial markets can be volatile in the short term, but over time, they tend to rise. A long-term mindset helps you endure market corrections or crashes without panicking.

3. Diversification of Assets: The Need of the Hour
"Don't put all your eggs in one basket" is one of the oldest investment adages, and it still rings true today. Diversification is spreading your investments across various asset classes which helps reduce risk and improve the potential for returns.

a) Why Diversification is Key: 
i) Minimizing Risk: Different asset classes (stocks, bonds, real estate, commodities) react differently to market conditions. If one sector underperforms, another might perform well, balancing your overall portfolio.
ii) Capitalizing on Opportunities: Diversifying your investments allows you to tap into multiple growth areas. For instance, while stocks may provide significant long-term returns, bonds or gold can act as a hedge during market downturns.

b) The Right Way to Diversify:
i) Asset Allocation: Determine the right mix of assets based on your risk tolerance and financial goals. For instance, a mix of equities for growth and bonds for stability creates a balanced approach.
ii) Geographical Diversification: Don't limit your investments to one country. Global markets offer opportunities that can complement your local investments and reduce geopolitical risks.
iii) Consider Alternative Investments: Beyond traditional asset classes, modern investors are exploring options like fractional real estate, cryptocurrencies, or peer-to-peer lending. However, approach these with caution and ensure they fit within your overall strategy.

Conclusion: Stay Focused, Stay Informed
Investing can be an incredible tool for building wealth, but it requires discipline, strategy, and patience. By avoiding the herd mentality, maintaining a long-term focus, and diversifying your assets, you set yourself up for sustainable financial success. The key is to stay informed and stick to your plan, adjusting when necessary but never letting emotions like fear or haste dictate your decisions. Remember, wealth-building is a journey, not a race. Embrace these three guardrails, and you'll be better equipped to navigate the unpredictable world of investing!`,
    author: "Resolute Corp",
    date: "30-09-2024",
    category: "Investing",
    banner: blog1,
  },
  
];

const BlogPost = ({ post, isExpanded, onToggle }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden">
    <div className="relative">
      <img src={post.banner} alt={post.title} className="w-full h-auto object-cover" />
      <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-4">
        <h2 className="md:text-3xl text-sm font-semibold mb-2">{post.title}</h2>
        <span className="text-sm">{post.date}</span>
        <span className="text-sm ml-4">{post.author}</span>
      </div>
    </div>
    <div className="p-6">
      <p className="text-gray-600 mb-4">{post.excerpt}</p>
      <button 
        onClick={onToggle}
        className="text-[#0E4669] font-medium flex items-center hover:text-blue-600"
      >
        {isExpanded ? (
          <>Read Less <ChevronUp className="ml-2 h-4 w-4" /></>
        ) : (
          <>Read More <ChevronDown className="ml-2 h-4 w-4" /></>
        )}
      </button>
    </div>
    {isExpanded && (
      <div className="px-6 py-4 bg-gray-50">
        <p className="text-gray-700 whitespace-pre-line">{post.content}</p>
      </div>
    )}
  </div>
);

const Blog = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [expandedPost, setExpandedPost] = useState(null);

  const categories = useMemo(() => {
    return ["All", ...new Set(blogPosts.map(post => post.category))];
  }, []);

  const filteredPosts = useMemo(() => {
    return selectedCategory === "All" 
      ? blogPosts 
      : blogPosts.filter(post => post.category === selectedCategory);
  }, [selectedCategory]);

  const toggleExpand = (postId) => {
    setExpandedPost(expandedPost === postId ? null : postId);
  };

  return (
    <section className="bg-[#F4F5F6]">
      <div className="pb-6">
      <div className='bg-[#0E4669] text-white py-16 px-8 pt-6 h-410 md:p-6 lg:p-12'
          style={{
            backgroundImage: `url(${portfolioBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '250px'
          }}
        ><h1 className="text-4xl font-bold text-center mb-3 py-5">Blogs</h1>
        <h4 className='text-center text-xl mb-5 font-semibold'>Insights on Investments and Growth</h4>
        </div>
        <div className="flex flex-wrap justify-center m-4">
          {categories.map(category => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`m-2 px-4 py-1 rounded-lg ${
                selectedCategory === category
                  ? "bg-[#0E4669] text-white"
                  : "bg-white text-[#0E4669] hover:bg-blue-100"
              }`}
            >
              {category}
            </button>
          ))}
        </div>

        <div className="grid grid-cols-1 gap-8 container mx-auto px-4  ">
          {filteredPosts.map(post => (
            <BlogPost
              key={post.id}
              post={post}
              isExpanded={expandedPost === post.id}
              onToggle={() => toggleExpand(post.id)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blog;